import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/contact/_contactLocations.scss';

class ContactMidlothian extends Component {
  render() {
    const { contactData } = this.props;
    return (
      <>
        <section className="contact-locations-content h-100">
          <Container fluid className="contact-henrico-containter">
            <Row>
              <Col md={6} className="my-auto">
                <div className="text-center mt-2">
                  <h1 className="contact-locations-title">
                    {contactData[0].clinicInfo.midlothianAddress.city}
                  </h1>
                </div>
                <Row className="text-center">
                  <Col md={12}>
                    <p>
                      {contactData[0].clinicInfo.midlothianAddress.address}{' '}
                      <br /> {contactData[0].clinicInfo.midlothianAddress.city},{' '}
                      {contactData[0].clinicInfo.midlothianAddress.state},{' '}
                      {contactData[0].clinicInfo.midlothianAddress.zip} <br />
                      <b>
                        <a
                          href={
                            contactData[0].clinicInfo.midlothianAddress
                              .altPhoneNumber
                          }
                        >
                          {
                            contactData[0].clinicInfo.midlothianAddress
                              .phoneNumber
                          }
                        </a>
                      </b>
                    </p>
                  </Col>
                  {/* <Col md={6}>
                    <p>
                      {contactData[0].clinicInfo.midlothianAddress.phoneNumber}
                    </p>
                  </Col> */}
                </Row>
                <Row className="text-center">
                  <Col md={6}>
                    <p className="custom-locations-hours">
                      <span className="custom-locations-days">Monday: </span>
                      {contactData[0].clinicInfo.midlothianHours.monday}
                      <br />
                      <span className="custom-locations-days">Tuesday: </span>
                      {contactData[0].clinicInfo.midlothianHours.tuesday}
                      <br />
                      <span className="custom-locations-days">Wednesday: </span>
                      {contactData[0].clinicInfo.midlothianHours.wednesday}
                      <br />
                      <span className="custom-locations-days">Thursday: </span>
                      {contactData[0].clinicInfo.midlothianHours.thursday}{' '}
                      <br />
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="custom-locations-hours">
                      <span className="custom-locations-days">Friday: </span>
                      {contactData[0].clinicInfo.midlothianHours.friday} <br />
                      <span className="custom-locations-days">Saturday: </span>
                      {contactData[0].clinicInfo.midlothianHours.saturday}{' '}
                      <br />
                      <span className="custom-locations-days">Sunday: </span>
                      {contactData[0].clinicInfo.midlothianHours.sunday} <br />
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="text-center mt-4 map-responsive">
                <iframe
                  title="Tulips Periodontics &amp; Dental Impants"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d792.2581022164647!2d-77.6481715707713!3d37.412708863951785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b17572fda46067%3A0xc4f8e4d632757f52!2sTulips%20Periodontics%20%26%20Dental%20Implants!5e0!3m2!1sen!2sus!4v1625517273545!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  allowFullScreen=""
                  loading="lazy"
                  frameBorder="0"
                />
              </Col>
            </Row>
            <Row className="my-5">
              <Col md={3}>
                <a
                  href={
                    contactData[0].clinicInfo.midlothianAppointment
                      .midlothianAppointmentLink
                  }
                  className="contact-book-now-henrico"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.midlothianAppointment
                      .midlothianAppointmentText
                  }
                  {/* <FaArrowRight className="mx-3 custom-locations-arrow" /> */}
                </a>
              </Col>
              <Col md={3}>
                <a
                  href={
                    contactData[0].clinicInfo.midlothianPatientPortal
                      .midlothianPatientPortalLink
                  }
                  className="contact-book-now-henrico"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.midlothianPatientPortal
                      .midlothianPatientPortalText
                  }
                  {/* <FaArrowRight className="mx-3 custom-locations-arrow" /> */}
                </a>
              </Col>
              <Col md={3}>
                <a
                  href={
                    contactData[0].clinicInfo.midlothianMambershipPlan
                      .midlothianMambershipPlanLink
                  }
                  className="contact-book-now-henrico"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.midlothianMambershipPlan
                      .midlothianMambershipPlanText
                  }
                  {/* <FaArrowRight className="mx-3 custom-locations-arrow" /> */}
                </a>
              </Col>
              <Col md={3}>
                <a
                  href={
                    contactData[0].clinicInfo.midlothianFinancingOptions
                      .midlothianFinancingOptionsLink
                  }
                  className="contact-book-now-henrico"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.midlothianFinancingOptions
                      .midlothianFinancingOptionsText
                  }
                  {/* <FaArrowRight className="mx-3 custom-locations-arrow" /> */}
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// export default ContactMidlothian;

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query ContactMidlothianQuery {
        allContactJson {
          nodes {
            clinicInfo {
              midlothianAddress {
                address
                city
                state
                zip
                phoneNumber
                altPhoneNumber
              }
              midlothianHours {
                monday
                tuesday
                wednesday
                thursday
                friday
                saturday
                sunday
              }
              midlothianAppointment {
                midlothianAppointmentLink
                midlothianAppointmentText
              }
              midlothianPatientPortal {
                midlothianPatientPortalLink
                midlothianPatientPortalText
              }
              midlothianMambershipPlan {
                midlothianMambershipPlanLink
                midlothianMambershipPlanText
              }
              midlothianFinancingOptions {
                midlothianFinancingOptionsLink
                midlothianFinancingOptionsText
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <ContactMidlothian contactData={data.allContactJson.nodes} />
    )}
  />
);

ContactMidlothian.propTypes = {
  contactData: PropTypes.array.isRequired,
};
