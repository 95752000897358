import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import { FaArrowRight } from 'react-icons/fa';
// import axios from 'axios';
import '../../scss/contact/_contactLocations.scss';

class ContactHenrico extends Component {
  render() {
    const { contactData } = this.props;
    return (
      <>
        <section className="contact-locations-content h-100">
          <Container fluid className="contact-henrico-containter">
            <Row>
              <Col md={6} className="my-auto">
                <div className="text-center mt-2">
                  <h1 className="contact-locations-title">
                    {contactData[0].clinicInfo.henricoAddress.city}
                  </h1>
                </div>
                <Row className="text-center">
                  <Col md={12}>
                    <p>
                      {contactData[0].clinicInfo.henricoAddress.address} <br />{' '}
                      {contactData[0].clinicInfo.henricoAddress.city},{' '}
                      {contactData[0].clinicInfo.henricoAddress.state},{' '}
                      {contactData[0].clinicInfo.henricoAddress.zip} <br />
                      <b>
                        <a
                          href={
                            contactData[0].clinicInfo.henricoAddress
                              .altPhoneNumber
                          }
                        >
                          {contactData[0].clinicInfo.henricoAddress.phoneNumber}
                        </a>
                      </b>
                    </p>
                  </Col>
                  {/* <Col md={6}>
                    <p>
                      {contactData[0].clinicInfo.henricoAddress.phoneNumber}
                    </p>
                  </Col> */}
                </Row>
                <Row className="text-center">
                  <Col md={6}>
                    <p className="custom-locations-hours">
                      <span className="custom-locations-days">Monday: </span>
                      {contactData[0].clinicInfo.henricoHours.monday}
                      <br />
                      <span className="custom-locations-days">Tuesday: </span>
                      {contactData[0].clinicInfo.henricoHours.tuesday}
                      <br />
                      <span className="custom-locations-days">Wednesday: </span>
                      {contactData[0].clinicInfo.henricoHours.wednesday}
                      <br />
                      <span className="custom-locations-days">Thursday: </span>
                      {contactData[0].clinicInfo.henricoHours.thursday} <br />
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="custom-locations-hours">
                      <span className="custom-locations-days">Friday: </span>
                      {contactData[0].clinicInfo.henricoHours.friday} <br />
                      <span className="custom-locations-days">Saturday: </span>
                      {contactData[0].clinicInfo.henricoHours.saturday} <br />
                      <span className="custom-locations-days">Sunday: </span>
                      {contactData[0].clinicInfo.henricoHours.sunday} <br />
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="text-center mt-4 map-responsive">
                <iframe
                  title="Tulips Periodontics &amp; Dental Impants"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3159.9432753789006!2d-77.55274668454457!3d37.62702207978671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b115241f598867%3A0x97e3cbe0162b0480!2sTulips%20Periodontics%20%26%20Dental%20Implants!5e0!3m2!1sen!2sus!4v1619656971434!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  allowFullScreen=""
                  loading="lazy"
                  frameBorder="0"
                />
              </Col>
            </Row>
            <Row className="my-5">
              <Col md={3}>
                <a
                  href={
                    contactData[0].clinicInfo.henricoAppointment
                      .henricoAppointmentLink
                  }
                  className="contact-book-now-henrico"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.henricoAppointment
                      .henricoAppointmentText
                  }
                  {/* <FaArrowRight className="mx-3 custom-locations-arrow" /> */}
                </a>
              </Col>
              <Col md={3}>
                <a
                  href={
                    contactData[0].clinicInfo.henricoPatientPortal
                      .henricoPatientPortalLink
                  }
                  className="contact-book-now-henrico"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.henricoPatientPortal
                      .henricoPatientPortalText
                  }
                  {/* <FaArrowRight className="mx-3 custom-locations-arrow" /> */}
                </a>
              </Col>
              <Col md={3}>
                <a
                  href={
                    contactData[0].clinicInfo.henricoMambershipPlan
                      .henricoMambershipPlanLink
                  }
                  className="contact-book-now-henrico"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.henricoMambershipPlan
                      .henricoMambershipPlanText
                  }
                  {/* <FaArrowRight className="mx-3 custom-locations-arrow" /> */}
                </a>
              </Col>
              <Col md={3}>
                <a
                  href={
                    contactData[0].clinicInfo.henricoFinancingOptions
                      .henricoFinancingOptionsLink
                  }
                  className="contact-book-now-henrico"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.henricoFinancingOptions
                      .henricoFinancingOptionsText
                  }
                  {/* <FaArrowRight className="mx-3 custom-locations-arrow" /> */}
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// export default ContactHenricoTest;

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query ContactHenricoQuery {
        allContactJson {
          nodes {
            clinicInfo {
              henricoAddress {
                address
                city
                state
                zip
                phoneNumber
                altPhoneNumber
              }
              henricoHours {
                monday
                tuesday
                wednesday
                thursday
                friday
                saturday
                sunday
              }
              henricoAppointment {
                henricoAppointmentLink
                henricoAppointmentText
              }
              henricoPatientPortal {
                henricoPatientPortalLink
                henricoPatientPortalText
              }
              henricoMambershipPlan {
                henricoMambershipPlanLink
                henricoMambershipPlanText
              }
              henricoFinancingOptions {
                henricoFinancingOptionsLink
                henricoFinancingOptionsText
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <ContactHenrico contactData={data.allContactJson.nodes} />
    )}
  />
);

ContactHenrico.propTypes = {
  contactData: PropTypes.array.isRequired,
};
