import React, { Component } from 'react';
import { Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import '../../scss/contact/_contact.scss';
import ContactHenrico from './ContactHenrico';
import ContactMidlothian from './ContactMidlothian';

class Contact extends Component {
  render() {
    return (
      <>
        <section className="contact-content h-100">
          <Container fluid className="contact-containter">
            <Row>
              <Col md={12} className="text-center contact-sectionTitle mb-4">
                <h1>Contact Us</h1>
              </Col>
              <Col md={12}>
                <Tab.Container defaultActiveKey="henrico">
                  <Row>
                    <Col md={6}>
                      <Nav variant="none" className="flex-column">
                        <Nav.Item className="contact-nav text-center">
                          <Nav.Link eventKey="henrico">Henrico</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col md={6}>
                      <Nav variant="none" className="flex-column">
                        <Nav.Item className="contact-nav text-center">
                          <Nav.Link eventKey="midlothian">Midlothian</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <Tab.Content>
                    <Tab.Pane eventKey="henrico">
                      <ContactHenrico />
                    </Tab.Pane>
                    <Tab.Pane eventKey="midlothian">
                      <ContactMidlothian />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Contact;
