import React from 'react';

import Layout from '../layouts/layout';
import SEO from '../components/seo';
import '../scss/_index.scss';
import Contact from '../components/Contact/Contact';

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <Contact />
  </Layout>
);

export default ContactPage;
